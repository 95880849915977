import React from "react";
import Layout from "../components/layout";
import { PrivacyPolicy } from "../components/privacy-policy";

const PrivacyPolicyPage = () => (
  <Layout>
    <PrivacyPolicy />
  </Layout>
);

export default PrivacyPolicyPage;
